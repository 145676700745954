<template>
  <b-card v-if="subscriptionNotificationText">
    <b-row class="d-flex align-items-center">
      <b-col>
        {{ subscriptionNotificationText }}
      </b-col>
      <b-col cols="auto">
        <subscription-notification-link />
        <feather-icon
          icon="XIcon"
          size="20"
          role="button"
          @click="setIsSubscriptionNotification(false)"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import mixinSubscriptionNotification from '@/mixins/subscription-notification'
import SubscriptionNotificationLink from '@/components/SubscriptionNotificationLink.vue'

export default {
  name: 'SubscriptionNotification',
  components: {
    BCard,
    BRow,
    BCol,
    SubscriptionNotificationLink,
  },
  mixins: [
    mixinSubscriptionNotification,
  ],
  methods: {
    ...mapActions({
      setIsSubscriptionNotification: 'main/setIsSubscriptionNotification',
    }),
  },
}
</script>
